import React, { useEffect, useState } from "react";
import CustomerServices from "../../services/customer-services";
import { _l, getPreviewFromURL, removeDuplicateObjects, showError } from "../../hooks/utilities";
import Lightbox from "yet-another-react-lightbox";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import Toasts from "../Toasts/Toasts";
import { useParams } from "react-router-dom";
import { showConfirmation, showMessage, toastCloseAction } from "../../actions/messages";
import { useDispatch, useSelector } from "react-redux";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import {  getTaskAttechments, setActionForLeaseDocument, setTypeOfLeaseDocument } from "../../actions/customer";
import { setComments } from "../../actions/documents-actions/documents-actions";
import { callAction } from "../../actions/chat";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Spinner } from "react-bootstrap";
import DocumentPreview from "../Documents/DocumentPreview";
import leaseServices from "../../services/lease-services";
import PaperclipIcon from "../../assets/icons/PaperclipIcon";
import DotsThreeVerticalIcon from "../../assets/icons/DotsThreeVerticalIcon";
import CloudArrowUpIcon from "../../assets/icons/CloudArrowUpIcon";
import MailContext from "../../assets/icons/MailContext";

const FileUploadComponent = ({ task_id, projectId, attachmentCountUpdate, closeAllTost, toastClose, searchAttchment = '', isExternal, readOnly = false, isConvertedTask = false, isRequestTask = false, taskDetails, type= "" ,handleAction,data, showFullscreen,documents = [], setSelectedAttachment = () =>{}, setDocuments=()=>{}, attachmentURL = "" }) => {
    
    const dispatch = useDispatch();
    let lastUniqueTaskId = -1;
    const {callStatus, isCallRecived} = useSelector((state) => state.chat);
    const documentData = useSelector((state) => state.documentsReducer.task_comments);
    const {isCallFullscreen, callComments, leaseDocAction, task_attachments, taskChecklist } = useSelector((state) => state.customer);
    const { id: hash_id } = useParams();
    
    const [taskAttachments, setTaskAttachments] = useState([]);
    const [defaultTaskAttachments, setDefaultTaskAttachments] = useState([]);
    const [openGallery, setOpenGallery] = useState(false);
    const contact_name = localStorage.getItem("full_name");
    const [toastHeader, setToastHeader] = useState("");
    const [confirmButtonText, setConfirmButtonText] = useState("");
    const [cancelButtonText, setCancelButtonText] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [toastClass, setToastClass] = useState("unsucess");
    const [toastMessage, setToastMessage] = useState("");
    const [attachmentId, setAttachmentId] = useState(0);
    const [displayAttachmentCount, setDisplayAttachmentCount] = useState(0);
    const [showTaskImage, setShowTaskImage] = useState(false);
    const [selectedAttachmentId, setSelectedAttachmentId] = useState(0);
    const [attachmentDetails, setAttachmentDetails] = useState({});
    const [loaderAttechment, setloaderAttechment] = useState(false)
    const [openPdf, setOpenPdf] = useState(false);
    const [lightboxInputArray, setLightboxInputArray] = useState([]);
  
    useEffect(() => {
        if (task_id && type != "leaseDocument" && lastUniqueTaskId != localStorage.getItem("uniqueTaskId")) {
            lastUniqueTaskId = localStorage.getItem("uniqueTaskId");
            dispatch(getTaskAttechments(task_id, projectId, '', (isExternal || hash_id) ? hash_id : false));
        }
    }, [task_id, taskAttachments.length]);

    useEffect(() => {
        if (task_attachments) {
            setTaskAttachments(task_attachments);
            setDefaultTaskAttachments(task_attachments);
        }
        if (type == "poi" && data && data.previewArray) {
            setTaskAttachments(data.previewArray);
            setDefaultTaskAttachments(data.previewArray);
        }
    }, [task_attachments, data, type]);

    useEffect(() => {
        // Calculate display attachment count
        let displayCount = 18;
        if(isExternal){
            displayCount = 40;
        }else{
            if(isRequestTask){
                displayCount = 22;
            }else{
                displayCount = 18;
            }
            if(showFullscreen){
                displayCount = displayCount + 18;
            }
        }
        setDisplayAttachmentCount(displayCount);
    }, [task_attachments, showFullscreen]);

    useEffect(() => {
        if (documents && documents.length > 0) {
            setTaskAttachments(documents);
            setDefaultTaskAttachments(documents);
        }
    }, [documents]);

    useEffect(() => {
        openToastClose()
    }, [toastClose]);

    const openToastClose = () => {
        setToastClass("unsucess");
        setToastHeader("");
        setConfirmButtonText("");
        setCancelButtonText("");
        setToastMessage(0);
        setAttachmentId(0);
        setShowToast(false);
    };

    const setAttchmentOnTop = (id) => {
        const targetAttchement = defaultTaskAttachments.filter((attachment) => attachment.id === id);
        const otherAttachments = defaultTaskAttachments.filter((attachment) => attachment.id != id);
        setDefaultTaskAttachments(targetAttchement.concat(otherAttachments));
    };

    const deleteImage = (id, comment_id) => {
        CustomerServices.deleteTaskDocument(projectId, comment_id, 1, id).then((res) => {
            if (res && res.status == 1) {
                attachmentCountUpdate(task_id, -1);
                setTaskAttachments(taskAttachments.filter((attachment) => attachment.id !== id));
                dispatch(toastCloseAction());
                let newArr = documentData
                newArr.forEach(com => {
                    if (com.id == task_id) {
                        com.comments = com.comments.filter((data) => data.id != comment_id)
                    }
                });
                dispatch(setComments(newArr.filter((c) => c.id == task_id)[0].comments, task_id, "task"));
            } else {
                dispatch(showMessage("unsucess", _l("l_error"), res.message));
            }
        });
    };

    useEffect(() => {
        if (searchAttchment != "") {
            let tempAttchments = defaultTaskAttachments.filter(
                (attachment) => {
                    if (localStorage.getItem('user_type') == 'contact') {
                        return attachment && attachment.contact_full_name &&
                        attachment.contact_full_name.toLowerCase().includes(searchAttchment.toLowerCase())
                    } else {
                        return attachment && attachment.staff_full_name &&
                        attachment.staff_full_name.toLowerCase().includes(searchAttchment.toLowerCase())
                    }
                }
            )
            setTaskAttachments(tempAttchments);
        } else {
            setTaskAttachments(defaultTaskAttachments && defaultTaskAttachments.length > 0 ? defaultTaskAttachments : documents); 
        }
    }, [searchAttchment]);

    const handleDeleteFile = (id, comment_id) => {
        dispatch(
            showConfirmation(
                _l("l_are_you_sure"),
                _l("l_you_want_to_delete_this_attachment"),
                _l("l_delete"),
                _l("l_cancel"),
                undefined,
                () => { deleteImage(id, comment_id) }
            )
        );
    }

    const handleDownloadFile =(url)=>{
        window.open(url, "_self");
    }

    const getFileDetails = (fileName) => {
        const lastIndex = fileName && fileName.lastIndexOf('.');
        if (lastIndex === -1) {
            // File has no extension
            return { type: 'Unknown', extension: 'None' };
        }
        const fileExtension = fileName && fileName.slice(lastIndex + 1);
        return fileExtension;
    };

    const handleLeaseFileuploads =  async(files) =>{
        setloaderAttechment(true);
        let response = await leaseServices.uploadLeaseDocuments(projectId, task_id, files)
        if (response && response.status) {
            let resFiles =  response.data.lease_document_details.map((img)=> {return{...img, image_url : img.url}})
            setDocuments(taskAttachments.concat(resFiles));
        } else {
            showError("l_something_went_wrong")
        }
        setloaderAttechment(false);
    }

    const handleDeleteLeaseFile = async(id)=>{
        let response = await leaseServices.deleteLeaseDocuments(projectId, task_id, id)
        if (response && response.status) {
            setDocuments(documents.filter((d)=>d.id != id));
        } else {
            showError("l_something_went_wrong")
        }
    }

    const getFileType = (url) => {
        if (url) {      
            const fileExtension = url.split(".").pop().toLowerCase();
            if (fileExtension === "pdf") {
                return "pdf";
            } else if (["jpg", "jpeg", "png"].includes(fileExtension)) {
                return "image";
            } else if (["txt"].includes(fileExtension)) {
                return "text";
            } else if (["doc","docx"].includes(fileExtension)) {
                return "doc";
            } else {
                return "other";
            }
        }
    };

    useEffect(() => {
        if (callComments && callComments.length > 0 && callStatus && callStatus.length > 0) {
            let tempAttchments = []
            callComments.map((comment) => {
                if(comment.attachments){
                    comment.attachments.map((attachment) => {
                        let obj={ 
                            altitude: comment.altitude ,
                            contact_firstname: comment.contact_full_name,
                            contact_full_name: comment.contact_full_name,
                            contact_id: comment.contact_id,
                            contact_image: null,
                            contact_lastname:  comment.contact_full_name,
                            creator_image_url: comment.profile_url,
                            dateadded: comment.dateadded, 
                            file_name: attachment.file_url,
                            file_type: getFileDetails(attachment.file_url),
                            id: comment.id,
                            image_url: attachment.file_url,
                            is_allowed_to_edit: 0,
                            latitude: "0",
                            longitude: "0",
                            staff_firstname: null,
                            staff_full_name: " ",
                            staff_image: null,
                            staff_lastname: null,
                            staffid: "0",
                            task_comment_id: "",
                            task_id: callStatus.task_id,
                        }
                        tempAttchments.push(obj);
                    })
                }
            })
            setTaskAttachments(tempAttchments);
        }
    }, [JSON.stringify(callComments)]);

    useEffect(() => {
        if(callStatus && callStatus.attachmentsList){
            setTaskAttachments(callStatus.attachmentsList);
        }
    }, [JSON.stringify(callStatus)]);

    useEffect(() => {
        const fileUpload = document.getElementById('fileUpload');
        if (type !== "leaseDocument") {
            fileUpload.removeAttribute('accept');
        }else{
            fileUpload.setAttribute('accept', '.pdf,.doc,.docx,.txt,image/*');
        }
    }, []);

    useEffect(() => {
        return () => {
            if (lastUniqueTaskId != -1) {
                lastUniqueTaskId = -1;
            }
        }
    }, []);

    useEffect(() => {
        if (documents && documents.length) {
            setAttachmentDetails(documents.find(img => String(img.image_url) == String(attachmentURL)));
        }
    }, []);

    useEffect(() => {
    if (leaseDocAction == "filelog" || leaseDocAction == "edit") {
        setShowTaskImage(true);
        setAttachmentDetails(documents.find(img => String(img.image_url) == String(attachmentURL)));
        dispatch(setTypeOfLeaseDocument(getFileType(attachmentURL)));
    }
    }, [leaseDocAction]);

    return (
        <>
            {showToast ? 
            <Toasts
                delay={2000}
                handler={() => {
                    setShowToast(false);
                    setAttachmentId(0);
                }}
                header={toastHeader}
                message={toastMessage}
                toastClass={toastClass}
                cancelButtonText={cancelButtonText}
                cancelButtonHandler={() => {
                    setShowToast(false);
                    setAttachmentId(0);
                }}
                confirmButtonText={confirmButtonText}
                confirmButtonHandler={() => {
                    deleteImage(attachmentId.id, attachmentId.comment_id);
                    setAttachmentId(0);
                    setShowToast(false);
                }}
            />
            : <></>
            }
            
            <div 
                className={`file-parent-box border-top h106px position-sticky bottom-0  mt-auto p-10 bottom67px z-index-12  ${type == "lead" ? "px-0" :""} ${isCallFullscreen ? "" : ""}`} 
                onClick={()=>{
                    dispatch(setActionForLeaseDocument());
                }}
            >
                <div className={` bottom-0 d-flex  ${type == "lead" ? "py-0 p-10" :""}`}>
                    <div className={`file-image-list  h85w115 pe10px pt-0 pb-0 ${isRequestTask ? taskDetails && taskDetails.email_sender ? '' : 'd-none' : ''} ${readOnly ? taskDetails && taskDetails.email_sender ? '' : "for-disabled" : ''} ${isExternal ? 'd-none' : ""} ${localStorage.getItem("accessToken") ? '' : type == "leaseDocument" ? 'd-none' :''}`}> 
                        <label className="c-list-icon w-100 position-relative">
                            <div className="uploadboxhw comman-round-box with-bg radius_3 text-center d-flex w-100 flex-column bg-white-03 upload-box justify-content-center bgleftpanelnew">
                                <input
                                    id="fileUpload"
                                    type="file"
                                    className="absolute-input "
                                    multiple
                                    disabled={readOnly ? taskDetails && taskDetails.email_sender ? false : true : false || loaderAttechment}
                                    onChange={(event) => {
                                        if (type == "leaseDocument") {
                                            const files = Array.from(event.target.files);
                                            const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];
                                            const isValid = files.every(file => allowedTypes.includes(file.type));
                                            if (!isValid) {
                                                showError("l_file_upload_error")
                                            }else{
                                                handleLeaseFileuploads(event.target.files)
                                            }
                                        } else if(type == "poi"){
                                            handleAction("poi_files",event.target.files)
                                        }else{
                                            setloaderAttechment(true)
                                            CustomerServices.addLinkComment(
                                                task_id,
                                                projectId,
                                                "",
                                                undefined,
                                                undefined,
                                                undefined,
                                                1,
                                                event.target.files
                                            ).then((res) => {
                                                let tempImage = [];
                                                if (res.status && res.data) {
                                                    res.data.files_list.map((img) => {
                                                        tempImage.push({
                                                        id: img.file_id,
                                                        image_url: img.image_url,
                                                        contact_full_name: contact_name,
                                                        file_name: img.file_name,
                                                        is_allowed_to_edit: 1,
                                                        creator_image_url: localStorage.getItem("contact_image"),
                                                        comment_file_id: res.data.comment_id
                                                        });
                                                    });
                                                    setTaskAttachments(
                                                        tempImage.concat(taskAttachments)
                                                    );
                                                    attachmentCountUpdate(task_id, res.data.files_list.length);
                                                    setloaderAttechment(false)
                                                }else{
                                                    setloaderAttechment(false)
                                                }
                                                
                                                if (isCallRecived) {
                                                    dispatch(callAction({
                                                        userId: callStatus.callerInfo._id,
                                                        receiverId: callStatus.receiverInfo._id,
                                                        callRole: callStatus.callRole,
                                                        actionKey: "newAttachments",
                                                        actionVal: res.data.comment_id,
                                                        extraData : {
                                                            projectId : localStorage.getItem("selectedOffice"),
                                                            checklist : removeDuplicateObjects(taskChecklist),
                                                            comments : callComments,
                                                            attachments : tempImage.concat(taskAttachments)
                                                        }
                                                    }));
                                                }
                                            });
                                        }
                                    }}
                                />
                                {loaderAttechment ? 
                                <>
                                    <Spinner size={32}  weight="light" className="c-icons mx-auto my-0 spinner-border"  />
                                    <div className="c-font f-10 color-white-60 mt-10"> {_l('l_please_wait')}</div>
                                </>
                                : <>
                                    <CloudArrowUpIcon className="HW32 my-0 opacity70"  />
                                    <div className="c-font f-10 color-white-60 mt-10"> {_l('l_upload_file')}</div>
                                </>
                                }
                            </div>
                            <div className="custom_file d-flex align-items-center justify-content-between w-100 d-none"> 
                                <div className="btn btn-sm btn-white-10">
                                    <span className="c-font f-10 color-white-60"> {_l('l_upload_file')}</span>
                                </div>
                                <PaperclipIcon className="c-upload_icon HW16" />
                            </div>
                        </label>
                    </div>
                    <Lightbox
                        open={openGallery}
                        close={() => setOpenGallery(false)}
                        slides={defaultTaskAttachments.map((img) => {
                        if (type == "poi") {
                            return { src: img.image_url };
                        } else {
                            return { src: getPreviewFromURL(img.image_url) };
                        }
                        })}
                    />
                    <div className="file-list-wrapper task-checklist-wrapper d-flex flex-column h-100 flex-grow-1 w100minus120">
                        <div className="d-flex gap-2 flex-wrap">
                            {taskAttachments && taskAttachments.length > 0 && taskAttachments.slice().reverse().filter((file,key) => key < displayAttachmentCount).map((attachment, index) => {
                                let preview = {};
                                if (type == "poi") {
                                    preview.url = attachment.image_url;
                                }else{
                                    preview.url = getPreviewFromURL(attachment.image_url);
                                }
                                return (
                                    <div key={index} className="file-image-list p-0 h35w35 uploadimages">
                                        <div className="c-list-icon position-relative h-100 with-overlay dropend bg-white-05">
                                            <div className={` comman-round-box with-bg radius_3 w-100 h-100 with-react-fancybox`} 
                                                onClick={() => {
                                                    setAttachmentDetails(attachment)
                                                    if (type == "leaseDocument") {
                                                        setShowTaskImage(false)
                                                        setSelectedAttachment(attachment)
                                                    } else {
                                                        if(isExternal) {
                                                            setOpenGallery(true);
                                                            setAttchmentOnTop(attachment.id);
                                                        } else {
                                                            setShowTaskImage(true);
                                                            setAttachmentDetails(attachment);
                                                            localStorage.setItem("isAttachPreview", true);
                                                        }
                                                    }
                                                }}
                                                type="button"
                                            >
                                                { typeof preview.url === "string" ?
                                                <div
                                                    className={`comman-bg-img bg-style-cover radius_3 d-flex ${getFileType(attachment.image_url) == "text" ? "h75w75 m-auto mt-1" : "h-100 w-100 "} ${index == (displayAttachmentCount - 1) ? "with-overlay-img": "" }`}
                                                    type="button"
                                                    style={{backgroundImage: `url('${preview.url}')`}}
                                                >
                                                    { index == (displayAttachmentCount - 1) ?
                                                    <span className="m-auto position-relaive z-index-2 c-font fw-semibold title-fonts f-18 span-color">
                                                        +{taskAttachments.length - displayAttachmentCount}
                                                    </span>
                                                    : <></>
                                                    }
                                                </div>
                                
                                                :["wav","mp3"].includes(getFileDetails(attachment.image_url || attachment.file_name)) ?
                                                <div className="chat-audio withf12 h-100 small pt-10px">
                                                    <AudioPlayer
                                                        style={{ width: '200px', margin: "0px" }}
                                                        layout="horizontal"
                                                        className="audio_player p-1 bg-transparent box-shadow-none h-100"
                                                        showJumpControls={false}
                                                        showSkipControls={false}
                                                        customAdditionalControls={[]}
                                                        customVolumeControls={[]}
                                                        src={attachment.image_url || attachment.file_name}
                                                        autoPlay={false}
                                                        autoPlayAfterSrcChange={false}
                                                    />
                                                </div>
                                                
                                                : 
                                                <div className={`comman-bg-img h-100 w-100 bg-style-cover radius_3 d-flex ${index == (displayAttachmentCount - 1) ? "with-overlay-img": "" }`} >
                                                    { index == (displayAttachmentCount - 1)
                                                    ? <span className="c-font f-14 fw-semibold m-auto position-absolute span-color start-50 title-fonts top-50 translate-middle z-index-2">
                                                        +{taskAttachments.length - displayAttachmentCount}
                                                        </span>
                                                    : <></>
                                                    }
                                                    {getFileType(attachment.image_url) == 'text' && taskDetails && taskDetails.email_sender ?  
                                                    <MailContext size={72} weight="light" className="c-icons HW30"/>
                                                    : <preview.url size={72} weight="light" className="c-icons HW30"/> }
                                                </div>
                                                }
                                                <div className="file-uploader-name d-none">
                                                    <div className="d-flex align-items-center">
                                                        <div className="h15w15 comman-round-box with-bg rounded-circle bg-white-05">
                                                            <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${attachment.creator_image_url}')` }}></div>
                                                        </div>
                                                        <div className="ps-2 text-truncate w100minus20">{localStorage.getItem('user_type') == 'contact' ? attachment.contact_full_name : attachment.staff_full_name}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <a href="#/"
                                                className={`line-height-0 file-action-option ${attachment.is_allowed_to_edit == 1 ? '' : 'for-disabled'} ${isExternal ? "d-none" :""}`} 
                                                type="button"
                                                id="DownloadDeleteDropdown"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <DotsThreeVerticalIcon className="HW16" />
                                            </a>
                                            {attachment.is_allowed_to_edit == 1 || isConvertedTask == false ?
                                            <ActionDropdown
                                                actions={[
                                                    {
                                                        actionName: "Delete",
                                                        actionHandler: () => {
                                                            if (type == "leaseDocument") {
                                                                handleDeleteLeaseFile(attachment.id)
                                                            }else{
                                                                handleDeleteFile(attachment.id, attachment.task_comment_id > 0 ? attachment.task_comment_id : attachment.id)
                                                            }
                                                        },
                                                        actionIcon: "TrashIcon",
                                                    },
                                                    {
                                                        actionName: "Download",
                                                        actionHandler: () => {
                                                            handleDownloadFile(attachment.image_url)
                                                        },
                                                        actionIcon:"DownloadSimpleIcon"
                                                    }
                                                ]}
                                            /> 
                                            : <></>}
                                        </div>
                                        <div className="max-w-130px pt-2 d-none">
                                            <div className="c-font text-truncate f-12 color-white-60 ">
                                                {attachment.file_name}
                                            </div>
                                            <div className="c-font text-truncate f-12 color-white-60 d-none">
                                                100KB
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {taskAttachments && taskAttachments.length == 0 ? 
                        <CommanPlaceholder imgWidth="small-image"  imgType="no-images" mainSpacing="m-auto" placeholderText = {_l('l_no_attachments')} />
                        : <></>}
                    </div>
                </div>
            </div>

            {showTaskImage && Object.keys(attachmentDetails).length > 0 ? 
            <CommanOffCanvas
                show={showTaskImage}
                handleClose={() => {
                    setShowTaskImage(false);
                }}
                data={{
                    id: attachmentDetails.id,
                    taskId: task_id,
                    projectId: projectId,
                    attachmentDetails: attachmentDetails,
                    taskDetails: taskDetails,
                    allAttachments: taskAttachments
                }}
                docType={"imageview"}
                setSelectedId={setSelectedAttachmentId}
                selectedTaskId={task_id}
                handleParentComponentAction={() => {}}
            /> 
            : <></>}

            {openPdf && lightboxInputArray.length ? 
            <DocumentPreview
                open={openPdf}
                setOpen={setOpenPdf}
                slides={lightboxInputArray}
            />
            : <></>}
        </>
    );
};
export default FileUploadComponent;
